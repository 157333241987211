import React from "react";
// import { Link } from "react-router-dom";
// import { ReactComponent as BackIcon } from "../icons/double-chevron.svg";
import { ReactComponent as BellIcon } from "../icons/support.svg";
import { ReactComponent as SettingsIcon } from "../icons/settings.svg";
// import { ReactComponent as Search } from "../icons/settings.svg";
import SearchBar from "../components/SearchBar";

const Header = () => {
  return (
    <div className="header">
      {/* <BackIcon className="headerIcon" /> */}
      {/* <SearchBar style={{ backgroundColor: "#f5f5f5" }} /> */}
      <div className="spacer"></div>
      <BellIcon onClick={(()=>{ window.location.href ="/logs" })} style={{ width: "24px" }} className="t-darkblue" />
      <div className="img-placeholder"></div>
      <SettingsIcon onClick={(()=>{ window.location.href ="/settings" })} style={{ width: "24px" }} className="t-darkblue" />
      {/* <nav>
        <ul style={{ listStyle: "none" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav> */}
    </div>
  );
};

export default Header;
