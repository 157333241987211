import React from "react";
import { useState } from "react";
import Modal from "react-modal";
import Button2 from "../components/Button2";
import InputText2 from "../components/InputText2";
import { ReactComponent as PhotoIcon } from "../icons/photo.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as SuccessIcon } from "../icons/success.svg";

const MerchantPage_Edit = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState("");

  // Function to handle adding a new service to the list
  const addService = () => {
    if (newService.trim() !== "") {
      setServices([...services, newService.trim()]);
      setNewService(""); // Clear input after adding
    }
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle form submission (e.g., send data to server)
    console.log({
      name,
      email,
      phone,
      address,
      services,
    });
    // Clear form fields after submission
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setServices([]);
    setNewService("");
  };

  //modal function
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openSuccessModal = () => {
    setModalIsOpen(true);
  };

  const closeSuccessModal = () => {
    setModalIsOpen(false);
  };

  //image handler
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      <Modal
        className="viewMerchantModal r medium"
        isOpen={modalIsOpen}
        onRequestClose={closeSuccessModal}
      >
        {/* <CloseIcon
          color="black"
          height="25px"
          className="alertCloseButton"
          onClick={closeSuccessModal}
        /> */}
        <div className="flexRow">
          <div style={{ width: "70px" }} className="flexRow">
            <SuccessIcon color="lime" />
          </div>
          <div>
            <p className="xl">Merchant Added!</p>
          </div>
        </div>
      </Modal>

      <p className="xl semibold">Edit Merchant Information</p>
      <div style={{ height: "10px" }}></div>

      <div className="flexRow">
        <p>Update merchant details and services below:</p>
        <div className="spacer"></div>
        <Button2
          style={{
            margin: "5px",
            width: "150px",
            backgroundColor: "#61dd48",
          }}
          onClick={openSuccessModal}
          type="submit"
          text={"Save merchant"}
        ></Button2>
      </div>

      <div style={{ height: "10px" }}></div>

      <div className="line2"></div>

      <div style={{ height: "10px" }}></div>

      <div style={{ height: "20px" }}></div>

      <div className="addMerchantCard">
        <div className="merchantForm">
          <div className="l semibold">Upload photo:</div>
          <label>
            <div className="merchantPhotoContainer">
              {selectedImage ? (
                <img src={selectedImage} alt="Merchant" />
              ) : (
                <PhotoIcon height={"350px"} />
              )}
            </div>
            <input type="file" onChange={handleImageChange} />
          </label>
        </div>
        <div className="merchantForm">
          <p className="l semibold">Enter Merchant Details:</p>
          <div style={{ height: "20px" }}></div>
          <form onSubmit={handleSubmit}>
            <InputText2
              title="Name"
              type="text"
              placeholder="Merchant Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <InputText2
              title="Email Address"
              type="text"
              placeholder="merchant@store.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <InputText2
              title="Contact Number"
              type="text"
              placeholder="639xxxxxxxxx"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <InputText2
              title="Address"
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </form>
        </div>

        <div className="merchantForm">
          <p className="l semibold">Add Services Offered:</p>
          <div style={{ height: "20px" }}></div>
          <div className="flexRow">
            <InputText2
              title="Services"
              type="text"
              value={newService}
              onChange={(e) => setNewService(e.target.value)}
              placeholder="Enter a service (Ex. Tech, Food Supply, etc.)"
            />
          </div>

          <div>
            <Button2
              onClick={addService}
              style={{
                margin: "5px",
                width: "150px",
                backgroundColor: "#202020",
              }}
              type="button"
              text={"Add Service"}
            ></Button2>
            <ul>
              {services.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantPage_Edit;
