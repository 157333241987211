import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { adminServices } from "../services/AdminServices";

const OtpPage = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState('');
  const [errorLogin,setErrorLogin] = useState('');
  const [loading, setLoading] = useState('');
  

  const handleUsername = (event)=>{
    setUserName(event.target.value);
  }

  const handlePassword = (event)=>{
    setPassword(event.target.value);
  }

  const Login = ()=>{
    setErrorLogin('');
    setLoading('Verifying OTP, please wait!');
    adminServices.verify(password).then((r)=>{
      if(r.status == 200){
          localStorage.setItem('appToken',r.data.token);
          window.location.href="/dashboard";
      }

      
    }).catch((e)=>{
      if(e.response.status == 409){
        setErrorLogin("Verification failed, please try again");
        setLoading('');
        return;
      }
    });

    

    // adminServices.getToken().then((r)=>{
    //   if(r.status==200){
    //     localStorage.setItem('adminServicesToken',r.data.token);
    //     window.location.href = '/dashboard';
    //   }
    // }).catch((e)=>{
    //   setErrorLogin('Error logging in, check your connection and try again.');
    // });

  }

  function togglePassword() {
    var passwordInput = document.getElementById("password");
    var showPasswordCheckbox = document.getElementById("showPassword");

    if (showPasswordCheckbox.checked) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }

  return (
    <div className="mainContainer">
      {/* Right Side */}
      <div className="loginPageContainer">
        <div className="loginPartition1">
          <div className="leftPart">
            {/* <div>
              <p>Login</p>
            </div>
            <div>
              <p>Sign Up</p>
            </div> */}
          </div>
        </div>

        {/* Left Side */}
        <div className="loginPartition2">
          <div className="rightPart">
            <div>
              <p className="xl medium">Verify OTP</p>
              <p className="r light">An email with a One Time Pin has been sent to your registered email.</p>
              <div style={{ height: "25px" }}></div>
              <div className="loginFields regular">
                <p>One Time Pin</p>
                <input
                  id="password"
                  className="m"
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={handlePassword}
                ></input>
              </div>

              <div className="flexRow" style={{color:'#FF0000',marginBottom:15, justifyContent:'center'}}>
                {errorLogin}
              </div>

              <div className="flexRow" style={{color:'green',marginBottom:15, justifyContent:'center'}}>
                {loading}
              </div>

              <div className="flexRow">
                <div className="flexRow m">
                  <input
                    type="checkbox"
                    id="showPassword"
                    onChange={togglePassword}
                  ></input>
                  <p>&nbsp;Show One Time Pin</p>
                </div>
                <div className="spacer"></div>
              </div>
              <div style={{ height: "20px" }}></div>
              <div className="loginButton">
                <Link style={{ textDecoration: "none" }}  onClick={Login}>
                  <p className="r">Verify</p>
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 0",
                }}
              >
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
