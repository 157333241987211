import React from "react";

const HomePage = () => {
  return (
    <div>
      <h2>Admin Booth Dashboard</h2>
      <p>Welcome to the dashboard!</p>
    </div>
  );
};

export default HomePage;
