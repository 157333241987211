import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable from "react-data-table-component";

const PadalaReportsFloating = () => {
  const [padalaList, setPadalaList] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(()=>{

    getPadala();

  },[]);

  const getPadala = async ()=>{
    var form = new FormData();
    form.append('params','');
    await adminServices.storedProcedure('padala_for_processing',form).then((r)=>{
      setPadalaList(null);
      setPadalaList(r.data);
      setData(r.data);
      setFilteredData(r.data);

      var _col = [
        { name: 'PDL Name', selector: data=>data.pdl, sortable: true },
        { name: 'PDL Number', selector: data=>data.receiver_pdl_no, sortable: true },
        { name: 'Padala Type', selector: data=>data.padala_type, sortable: true },
        { name: 'Gross Amount', selector: data=>data.gross_amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), sortable: true, right: true,
        headerStyle: {
          textAlign: 'center',
        }, },
        { name: 'Interest Rate', selector: data=>data.interest_rate.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), sortable: true, right: true,
        headerStyle: {
          textAlign: 'center',
        }, },
        { name: 'Net Amount', selector: data=>data.net_amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }), sortable: true, right: true,
          headerStyle: {
            textAlign: 'center',
          }, },
        { name: 'Status', selector: data=>data.status, sortable: true },
        { name: 'Sender', selector: data=>data.sender, sortable: true },
        { name: 'Transaction Date', selector: data=>data.transaction_date, sortable: true },
      ]

      setColumns(_col);

    }).catch((e)=>{
      if(e.response.status == 401){
        window.location.href = "/";
      }
    });
    

  }

  const handleRowClicked = (row)=>{
      var q =btoa(JSON.stringify(row));
      window.location.href="/reprocess?q=" + q;
  }

  return (
    <div>
      <h2>Padala For Processing</h2>
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          style={{zIndex:0}}
          onRowClicked={handleRowClicked}
      >
        
      </DataTable>
    </div>
  );
};

export default PadalaReportsFloating;
