import React from "react";
import { ReactComponent as AddIcon } from "../icons/add.svg";

const Button2 = ({ text, onClick, type, id, style, disabled=false }) => {

  const handleClick = (e) => {
    e.preventDefault(); // Prevent the default behavior
    onClick();
  };

  return (
    <label
      htmlFor={id}
      onClick={handleClick}
      className="button2Component "
      style={style}
    >
      <p style={{ textAlign: "center" }} className="r medium t-offwhite">
        {text}
      </p>

      <button id={id} type={type} style={{ display: "none" }}></button>
    </label>
  );
};

export default Button2;
