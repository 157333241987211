import React from "react";
import { ReactComponent as SearchIcon } from "../icons/search.svg";

const SearchBar = ({ value, onChange, placeholder }) => {
  return (
    <div
      className="flexRow m searchboxContainer"
      style={{ margin: "0 10px 0 0" }}
    >
      <SearchIcon
        className="btnIcon1"
        style={{ height: "20px", color: "#222222", padding: "10px" }}
      />
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="searchbox m"
      />
    </div>
  );
};

export default SearchBar;
