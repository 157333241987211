import { useEffect, useState } from "react";
import { adminServices } from "../services/AdminServices";
import Button2 from "../components/Button2";
import DataTable from "react-data-table-component";
import ConfirmationDialog from "../components/ConfirmationDialog";
import AlertModal from "../components/AlertModal";
import { redirect } from "react-router-dom";

const PDLTransactions = ()=>{
    const [pdlNumber, setPdlNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName,setMiddleName] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [facilityNumber, setFacilityNumber] = useState('');
    const [pin, setPin] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [options, setOptions] = useState([]);
    const [transactions, setTransactions] = useState(null);
    const [columns, setColumns] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [confirmVoid, setConfirmVoid] = useState(false);
    const [voidMessage, setVoidMessage] = useState('');
    const [toVoid, setToVoid] = useState({});

    const data = JSON.parse(atob(localStorage.getItem('selectedPDL')));

    useEffect(()=>{
        //getData();

        setPdlNumber(data.pdlNumber);
        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setDob(data.dob);
        setFacilityNumber(data.facilityNumber);
        setStatusId(data.statusId);
        getClientStatuses();
        getClientTransactions();

    },[]);

    const updateClient = async()=>{
        var form = data;
        form.firstName = firstName;
        form.middleName = middleName;
        form.lastName = lastName;
        form.dob = dob;
        form.gender = gender;
        form.facilityNumber = facilityNumber;
        form.pin = pin;
        form.statusId = statusId;

        delete form.walletAmount;
        console.log(form);

        setShowConfirm(false);

        await adminServices.updatePDL(form).then((r)=>{
            if(r.status === 204){
                var append = pin != '' ? "\n\rTemporary PIN: "  + pin + ", kindly take note" : ""
                setAlertMessage("PDL Profile Updated" + append);
                setShowAlert(true);
            }
        }).catch((e)=>{
            if(e.response.status === 401){
                window.location.href="/";
            }
            else{
                setAlertMessage(e.response.message);
                setShowAlert(true);
            }
        });
        
    }



    const getClientStatuses = async ()=>{
        var form = new FormData();
        form.append('params','');
        await adminServices.storedProcedure('get_client_statuses',data).then((r)=>{

            setOptions(r.data);

        }).catch((e)=>{
            if(e.response.status === 401){
                window.location.href='/';
            }
        });
    }

    const handleButtonClick = (e)=>{
        setToVoid(e);
        setVoidMessage("Are you sure you want to void this transaction?\n\r" + `${ e.transaction_type} : ${e.amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`);
        setConfirmVoid(true);
    }

    const getClientTransactions = async ()=>{
        var form = new FormData();
        form.append('params',data.id);
        await adminServices.storedProcedure('get_client_transactions_by_id',form).then((r)=>{
            setTransactions(r.data);
            setFilteredData(r.data);
            var _col = [
                { name: 'ID', selector: data=>data.id, sortable: true },
                { name: 'Transaction Type', selector: data=>data.transaction_type, sortable: true },
                { name: 'Reference No', selector: data=>data.ref_no, sortable: true },
                { name: 'Amount', selector: data=>data.amount.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }), sortable: true },
                { name: 'Remarks', selector: data=>data.remarks, sortable: true },
                { name: 'Timestamp', selector: data=>data.timestamp, sortable: true },
                { name: 'Action', cell: row => row.transaction_type_id == 2 || row.transaction_type_id == 3  ? <button type="button" onClick={() => handleButtonClick(row)}>Void</button> : null,sortable:false}
            ]
            setColumns(_col);

        }).catch((e)=>{
            if(e.response.status === 401){
                window.location.href='/';
            }
        });
    }

    const voidTransaction= async ()=>{

        var form = {
            "id": toVoid.id,
            "clientId": toVoid.client_id,
            "transactionTypeId": toVoid.transaction_type_id == 3 ? 5 : 4,
            "refNo": toVoid.ref_no,
            "amount": toVoid.amount,
            "remarks": toVoid.remarks + ";Voided",
            "timestamp": toVoid.timestamp,
        }

        

        await adminServices.updateClientTransaction(form).then(async (r)=>{
            if(r.status == 204){
                delete form.id;
                form.amount = Math.abs(toVoid.amount);
                form.transactionTypeId = 6;
                form.remarks = "Voided from transaction id:" + toVoid.id;
                
                await adminServices.addClientTransaction(form).then((r)=>{

                    setAlertMessage("Transaction Successfully Voided");
                    setShowAlert(true);
                    setConfirmVoid(false);

                    
                }).catch((e)=>{
                    if(e.response.status != 401){
                        setAlertMessage("Error Crediting Transaction");
                        setShowAlert(true);
                        setConfirmVoid(false);
                    }
                    else{
                        window.location.href = "/";
                    }
                })
            }
        }).catch((e)=>{
            if(e.response.status != 401){
                setAlertMessage("Error Voiding Transaction");
                setShowAlert(true);
                setConfirmVoid(false);
            }
            else{
                window.location.href = "/";
            }
        })

    }
   
    const generateRandomNumber = () => {
        const min = 100000;
        const max = 999999;
        const number = Math.floor(Math.random() * (max - min + 1)) + min;
        return number;
      };

    return (<div>
        <ConfirmationDialog 
            title={"Void Transaction"}
            show={confirmVoid}
            message={voidMessage}
            onCancel={()=>{ setConfirmVoid(false); }}
            onConfirm={()=>{ voidTransaction() }}
        >

        </ConfirmationDialog>
        <AlertModal
            show={showAlert}
            title={"PDL Updates"}
            onClose={()=>{ setShowAlert(false); }}
            message={alertMessage}
        >
        </AlertModal>
        <ConfirmationDialog
        show={showConfirm}
        title={"PDL Profile"}
        message={"Are you sure you want to update the PDL Profile?"}
        onCancel={()=>{ setShowConfirm(false); }}
        onConfirm={()=>{ updateClient() }}
        ></ConfirmationDialog>
        <div style={{
            display:"flex",
            flexDirection:'row'
        }}>
            <table width={'70%'} class='pdl-info'>
                    <tr>
                        <th>PDL Number</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                    </tr>
                    <tr>
                        <td>
                            {pdlNumber}
                        </td>
                        <td>
                            <input class='pdl-text' type='text' value={firstName} onChange={(e)=>{ setFirstName(e.target.value) }} />
                        </td>
                        <td>
                            <input class='pdl-text' type='text' value={middleName} onChange={(e)=>{ setMiddleName(e.target.value) }} />
                        </td>
                        <td>
                            <input class='pdl-text' type='text' value={lastName} onChange={(e)=>{ setLastName(e.target.value) }} />
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Date Of Birth</th>
                        <th>Gender</th>
                        <th>Facility Number</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input class='pdl-text' type='date' value={dob} onChange={(e)=>{ setDob(e.target.value) }} style={{width:'50%'}} />
                        </td>
                        <td>
                            <select  class='pdl-text' onChange={(e)=>{setGender(e.target.value)}} style={{width:'70%'}}>
                                <option value='M' selected={ data.gender === 'M' ? 'selected': '' }>Male</option>
                                <option value='F' selected={ data.gender === 'F' ? 'selected': '' }>Female</option>
                            </select>
                        </td>
                        <td>
                            <input class='pdl-text' type='text' value={facilityNumber} onChange={(e)=>{ setFacilityNumber(e.target.value) }}  />
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={4}>Status</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <select class='pdl-text' onChange={(e)=>{ setStatusId(e.target.value) }} style={{width:'50%'}}>
                                    {options && options.map((option) => (
                                        <option key={option.status_id} value={option.status_id} selected={ data.statusId === option.status_id ? 'selected' : '' }>
                                            {option.description}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        <td>
                            <strong>Enable Pin?</strong>&nbsp;
                            Yes<input type='radio' name="pin" value={"Yes"} onClick={()=>{ setPin(generateRandomNumber()); }}/>
                            No<input type='radio' name="pin" value={"No"} onClick={()=>{ setPin(''); }} />
                        </td>
                        <td>
                            <Button2
                                text={"Update PDL"}
                                onClick={()=>{ setShowConfirm(true); }}
                                style={{ width: "200px", backgroundColor: "#61dd48" }}
                            />
                        </td>
                    </tr>
            </table>
           
        </div>
        <div>
            <h3>PDL Transactions</h3>

            <DataTable 
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                style={{zIndex:0}}
            >

            </DataTable>
        </div>
    </div>);

}

export default PDLTransactions;