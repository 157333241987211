import React from "react";

const InputText2 = ({ title, type='text', placeholder, name, value, onChange }) => {
  return (
    <div style={{ margin: "5px", flex: "1" }}>
      <p className="semibold ">{title}</p>
      <input
        className="r form1"
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      ></input>
    </div>
  );
};

export default InputText2;
