import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DataTable from 'react-data-table-component';
import { walletServices } from "../services/WalletService";
import InputText1 from "./InputText1";
import { adminServices } from "../services/AdminServices";

const MerchantList = ({token,isOpen, onClose ,selectedInfo})=>{
    const [selectedData, setSelectedDate] = useState(null);
    const [clients, setClients] = useState(null);
    const [data, setData] = useState(null);
    const [columns, setColumns] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);


    useEffect(()=>{
        getMerchants();
    },[])

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter data based on search query
    const filteredRows = data.filter((row) =>
      row.pdl_number.toLowerCase().includes(query) ||
      row.first_name.toLowerCase().includes(query) ||
      row.last_name.toLowerCase().includes(query)
    );
    setFilteredData(filteredRows);
  };

    


    const getMerchants = async ()=>{
        var form = new FormData();

        form.append('params','');
        await adminServices.storedProcedure('merchant_list',form).then((r)=>{
            setData(r.data);
            setFilteredData(r.data);
            var _col = [
                { name: 'ID', selector: data=>data.id, sortable: true },
                { name: 'Merchant Name', selector: data=>data.merchant_name, sortable: true },
                { name: 'Description', selector: data=>data.description, sortable: true },
                { name: 'Type', selector: data=>data.type, sortable: true },
                { name: 'PDL', selector: data=>data.pdl_name, sortable: true }
            ]
            setColumns(_col);
        }).catch((r)=>{
            if(r.response.status === 401){
                window.location.href = "/";
            }
        });
    }

    const handleSelectedRow = (row)=>{
        console.log(row);
    }

    return (
        <div>
            <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            >

                <div style={{display:"flex",justifyContent:'end',position:'fixed',right:63,zIndex:999}}>
                    <button style={{backgroundColor:'#fff',
                        borderColor:'#fff',
                        color:'#555',
                        borderRadius:100,
                        fontSize:20,
                        fontWeight: 'bolder',
                        fontFamily: 'monospace',
                        borderWidth:0}}
                        onClick={onClose}
                        >
                            x
                        </button>
                </div>
                
                <div>
                    <div className="sc-fPXMhL QAQQD">Merchant List</div>
                    <div>
                        <input type="search" placeholder="Search" style={{
                            height:35,
                            width: '50%',
                            paddingLeft: 10
                        }}
                        onChange={handleSearchChange}
                        value={searchQuery}
                        ></input>
                    </div>
                    <DataTable 
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        onRowClicked={selectedInfo}
                    >

                    </DataTable>
                </div>

            </Modal>
        </div>
    )
}

export default MerchantList;