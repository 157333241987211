import axios, {AxiosResponse} from "axios";
let baseURL = process.env.REACT_APP_BASE_URL;

export const walletServices = {
    pdlList
}

async function pdlList(){

    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }

    return await axios.get(`${baseURL}clients`,config);
}