import React from "react";

const ContactPage = () => {
  return (
    <div>
      <h2>Contact Page</h2>
      <p>Get in touch with us!</p>
    </div>
  );
};

export default ContactPage;
