import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DataTable from 'react-data-table-component';
import { walletServices } from "../services/WalletService";
import InputText1 from "./InputText1";

const PDLList = ({token,isOpen, onClose ,selectedInfo})=>{
    const [selectedData, setSelectedDate] = useState(null);
    const [clients, setClients] = useState(null);
    const [data, setData] = useState(null);
    const [columns, setColumns] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);


    useEffect(()=>{
        getPDLProfiles();
    },[])

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter data based on search query
    const filteredRows = data.filter((row) =>
      row.pdlNumber.toLowerCase().includes(query) ||
      row.firstName.toLowerCase().includes(query) ||
      row.lastName.toLowerCase().includes(query)
    );
    setFilteredData(filteredRows);
  };

    


    const getPDLProfiles = ()=>{
        walletServices.pdlList().then((r)=>{
            setData(r.data);
            setFilteredData(r.data);
            var _col = [
                { name: 'ID', selector: data=>data.id, sortable: true },
                { name: 'PDL Number', selector: data=>data.pdlNumber, sortable: true },
                { name: 'First Name', selector: data=>data.firstName, sortable: true },
                { name: 'Middle Name', selector: data=>data.middleName, sortable: true },
                { name: 'Last Name', selector: data=>data.lastName, sortable: true },
                { name: 'Gender', selector: data=>data.gender, sortable: true },
                { name: 'Facility Number', selector: data=>data.facilityNumber, sortable: true },
            ]
            setColumns(_col);
        }).catch((r)=>{
            if(r.response.status === 401){
                window.location.href="/"
            }
        });
    }

    const handleSelectedRow = (row)=>{
        console.log(row);
    }

    return (
        <div>
            <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            >

                <div style={{display:"flex",justifyContent:'end',position:'fixed',right:63,zIndex:999}}>
                    <button style={{backgroundColor:'#fff',
                        borderColor:'#fff',
                        color:'#555',
                        borderRadius:100,
                        fontSize:20,
                        fontWeight: 'bolder',
                        fontFamily: 'monospace',
                        borderWidth:0}}
                        onClick={onClose}
                        >
                            x
                        </button>
                </div>
                
                <div>
                    <div className="sc-fPXMhL QAQQD">PDL Profiles</div>
                    <div>
                        <input type="search" placeholder="Search" style={{
                            height:35,
                            width: '50%',
                            paddingLeft: 10
                        }}
                        onChange={handleSearchChange}
                        value={searchQuery}
                        ></input>
                    </div>
                    <DataTable 
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        onRowClicked={selectedInfo}
                    >

                    </DataTable>
                </div>

            </Modal>
        </div>
    )
}

export default PDLList;