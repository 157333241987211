import { useState } from "react";
import InputText1 from "../components/InputText1";
import Button2 from "../components/Button2";
import AlertModal from "../components/AlertModal";
import { adminServices } from "../services/AdminServices";

const KioskPageAdd = ()=>{
    const [deviceId, setDeviceID] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [kioskName, setKioskName] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    return (<div style={{width:'50%'}}>
         <h2>Add Kiosk Terminal</h2>
         <AlertModal title={"Add Kiosk Terminal"} show={showAlert} message={alertMessage} onClose={()=>{setShowAlert(false)}}></AlertModal>
        <form className="padalaForm">
        <InputText1 title={"Kiosk Device ID"}
            value={deviceId}
            placeholder={"App Device ID"}
            onChange={(e)=>{
                setDeviceID(e.target.value);
            }}
        />

        <InputText1 title={"Serial Number ID"}
            value={serialNumber}
            placeholder={"Serial Number"}
            onChange={(e)=>{
                setSerialNumber(e.target.value);
            }}
        />

        <InputText1 title={"Kiosk Name"}
            value={kioskName}
            placeholder={"Kiosk Name"}
            onChange={(e)=>{
                setKioskName(e.target.value);
            }}
        />

        
        </form>
        <Button2
            text={"Submit"}
            onClick={async ()=>{
                
               if(deviceId === ''){
                setAlertMessage('Kiosk Device ID Required');
                setShowAlert(true);
                return;
               }

               if(serialNumber === ''){
                setAlertMessage('Serial Number Required');
                setShowAlert(true);
                return;
               }

               if(kioskName === ''){
                setAlertMessage('Kiosk Name Required');
                setShowAlert(true);
                return;
               }

               var form  = {
                    "appDeviceId" : deviceId,
                    "serialNumber" : serialNumber,
                    "kioskName" : kioskName
               }

               await adminServices.addKiosk(form).then((r)=>{
                if(r.status == 201 || r.status == 204 || r.status == 200){
                    window.location.href="/kiosk-status-reports";
                }
               }).catch((e)=>{
                    if(e.response.status == 401){
                        window.location.href = "/";
                    }

                    setAlertMessage("An error occured");
                    setShowAlert(true);
               });
                
            }}
            style={{ width: "200px", backgroundColor: "#61dd48" }}
            />
    </div>);
}

export default KioskPageAdd;