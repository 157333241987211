import React from "react";

const Dropdown = ({ title, data, name, value, onChange }) => {
  return (
    <div style={{ margin: "5px", flex: "1" }}>
      <p className="semibold ">{title}</p>
      {/* <input
        className="r form1"
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      ></input> */}
      <select
        title={title}
        name={name}
        onChange={onChange}
        className="dropDown"
      >
        {data.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
      </select>
    </div>
  );
};

export default Dropdown;
