import React from "react";

const AboutPage = () => {
  return (
    <div>
      <h2>About Page</h2>
      <p>Learn more about us on this page.</p>
    </div>
  );
};

export default AboutPage;
