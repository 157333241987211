import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import Button1 from "../components/Button1";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as EditIcon } from "../icons/edit.svg";
import DataTable from "react-data-table-component";
import { adminServices } from "../services/AdminServices";

const MerchantPage = () => {
  const [kioskSales, setKioskSales] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

 

  //modal function
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const openViewMerchantModal = (merchant) => {
    setSelectedMerchant(merchant);
    setModalIsOpen(true);
  };

  const closeViewMerchantModal = () => {
    setModalIsOpen(false);
    setSelectedMerchant(null);
  };

  //search function
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    

    getPadala();

    setDisplayedProducts(
      kioskSales.filter((product) =>
        product.merchant_name.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    );

  }, [searchTerm]);

  setTimeout(()=>{
    getPadala();
  },60000);

  const getPadala = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('pdl_merchants',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      var _col = [
        { name: 'PDL Name', selector: data=>data.first_name + " " + data.last_name, sortable: true },
        { name: 'Merchant Name', selector: data=>data.merchant_name, sortable: true },
        { name: 'Description', selector: data=>data.description , sortable: true },
        { name: 'Type', selector: data=>data.type, sortable: true },
        { name: 'Location', selector: data=>data.location, sortable: true, right: true},
        { name: 'Date Registered', selector: data=>data.reg_date, sortable: true },
    ]

    setColumns(_col);

    }).catch((e)=>{
      if(e.response.status == 401){
        window.location.href="/";
      }
    })

  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [merchantsPerPage, setMerchantsPerPage] = useState(10);

  // Get current merchants
  const indexOfLastProduct = currentPage * merchantsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - merchantsPerPage;
  const currentProducts = displayedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="mainContainer">
      {/* View merchant details modal */}
      
      {/* Header */}
      <div className="flexRow">
        <p className="semibold xl t-darkgray">Merchants</p>
        <div className="spacer"></div>
      </div>

      <div style={{ height: "25px" }}></div>

      {/* Search Row */}
      <div className="flexRow">
        <div>
          {/* <SearchBar
            placeholder={"Seach merchants"}
            value={searchTerm}
            onChange={handleSearch}
          /> */}
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <Button1
          text={"Add merchant"}
          onClick={() => {
            navigate("/merchant/add");
          }}
        />
      </div>

      <div style={{ height: "25px" }}></div>

      {/* Table */}
      <div className="merchantTableContainer">
        <DataTable 
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            style={{zIndex:0}}
        />
      </div>

      {/* <div style={{ height: "10px" }}></div> */}

      {/* Table Footer */}
      
          
      {/*  */}
    </div>
  );
};

export default MerchantPage;
