import { useState } from "react";

const EditableCell = ({ row, onSave, item ,type='text' }) => {
  const [value, setValue] = useState(row[item]);
  const handleBlur = () => {
    
    onSave(row.id, value);
  };

  return (
    <input
      type={type}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      style={{height:'80%',borderRadius:5, borderWidth: 1,textAlign:'center', width:50}}
    />
  );
};

export default EditableCell;