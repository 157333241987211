import React, { useEffect, useState } from 'react';
import InputText1 from '../components/InputText1';
import Button2 from '../components/Button2';
import PDLList from '../components/PDLList';
import { adminServices } from '../services/AdminServices';
const ReprocessPadala = ()=>{
    const [queryParams, setQueryParams] = useState({});
    const [data, setData] = useState({});
    const [interestRate, setInterestRate] = useState(0);
    const [receiver, setReceiver] = useState('');
    const [pdl, setPdl] = useState('');
    const [openPDLList, setOpenPDLList] = useState(false);
    const [dateTime, setDateTime] = useState(new Date())
    const [clientId, setClientId] = useState(0);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const queryParamsObj = {};
        for (let [key, value] of params.entries()) {
        queryParamsObj[key] = value;
        }
        setQueryParams(queryParamsObj);

        if (queryParamsObj.q) {
            parseData(queryParamsObj.q);
        }

    }, []);

    const handleSelectedRow = (e)=>{
        console.log(e);
       setReceiver(e.pdlNumber);
       setPdl(e.firstName + " " + e.lastName);
       setClientId(e.id);
       setOpenPDLList(false);

    }

    const parseData = async (q)=>{
        try {
            console.log(q);
            const decodedString = atob(q);
            const parsedData = JSON.parse(decodedString);
            setData(parsedData);

            setInterestRate(parsedData.interest_rate);
            setReceiver(parsedData.receiver_pdl_no);
            setPdl(parsedData.pdl);
            setDateTime(new Date(parsedData.transaction_date));

          } catch (error) {
            console.error('Failed to parse data:', error);
          }
    }

    return (<div>
        <PDLList
            token={localStorage.getItem('appToken')}
            isOpen={openPDLList}
            onClose={()=>{setOpenPDLList(false)}}
            selectedInfo={handleSelectedRow}
        ></PDLList>
        <form className="padalaForm">
            <InputText1
                title="Sender Name"
                placeholder="Sender Name"
                name="firstname"
                value={data.sender}
                readonly={true}
              />
              <InputText1
                title={data.status === "On Hold" ? "PDL Name" : "PDL Name (Click to change PDL)"}
                placeholder="First Name"
                name="firstname"
                value={pdl}
                readonly={true}
                onClick={()=>{
                    if(data.status === "On Hold"){ return;}
                    setOpenPDLList(true);
                }}
              />
              <InputText1
                title="PDL Number"
                placeholder="PDL Number"
                name="middleName"
                value={receiver}
                readonly={true}
              />
              <InputText1
                title="Padala Type"
                placeholder="Padala Type"
                name="lastname"
                value={data.padala_type}
                readonly={true}
              />

              <InputText1
                title="Gross Amount"
                placeholder="0.00"
                value={data.gross_amount}
                readonly={true}
              />

              <InputText1
                title="Interate Rate (editable)"
                placeholder="0.00"
                value={interestRate}
                type='number'
                readonly={false}
                onChange={(e)=>{ setInterestRate(e.target.value) }}
              />

              <InputText1
                title="Net Amount"
                placeholder="0.00"
                value={data.gross_amount - parseFloat(interestRate)}
                readonly={true}
              />

              <InputText1
                title="Status"
                placeholder="Status"
                name="firstname"
                value={data.status}
                readonly={true}
              />

              <InputText1
                title="Transaction Date"
                placeholder="date"
                value={
                    new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      }).format(dateTime)

                }
                readonly={true}
              />

              <Button2
                text={"Reprocess"}
                onClick={()=>{

                    var form = {
                        "id": data.id,
                        "refNo": data.ref_no,
                        "senderFname": data.sender,
                        "receiverPdlNo": receiver,
                        "padalaType": data.padala_type_id,
                        "grossAmount": data.gross_amount,
                        "interestRate": interestRate,
                        "netAmount": (data.gross_amount - interestRate),
                        "status": "Success",
                    }

                    adminServices.updatePadala(form).then((r)=>{
                        if(r.status === 204){
                            alert('Transaction Successfully Reprocessed');
                            window.location.href='/floating-padala-reports'
                        }
                    }).catch((e)=>{
                        console.log(e);
                        alert("An error occured");
                    });

                }}
                style={{ width: "200px", backgroundColor: "#61dd48" }}
              />
            </form>
    </div>);
}

export default ReprocessPadala;

