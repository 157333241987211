import React from "react";
import { ReactComponent as AddIcon } from "../icons/add.svg";

const Button1 = ({ text, onClick, type }) => {
  return (
    <div>
      <label htmlFor="button1" className="flexRow button1">
        <AddIcon
          className="btnIcon1"
          style={{ height: "25px", color: "#f5f5f5" }}
        />
        <p className="m medium t-offwhite">{text}</p>
      </label>
      <button
        id="button1"
        onClick={onClick}
        type={type}
        style={{ display: "none" }}
      ></button>
    </div>
  );
};

export default Button1;
