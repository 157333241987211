import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable from "react-data-table-component";

const BeuPadalaReports = () => {
  const [padalaList, setPadalaList] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(()=>{

    getPadala();

  },[]);

  const getPadala = async ()=>{
    var form = new FormData();
    form.append('params','');
    await adminServices.storedProcedure('bucor_padala_report',form).then((r)=>{
      setPadalaList(null);
      setPadalaList(r.data);
      setData(r.data);
      setFilteredData(r.data);

      var _col = [
        { name: 'PDL Name', selector: data=>data.pdlName, sortable: true },
        { name: 'PDL Number', selector: data=>data.pdl_number, sortable: true },
        { name: 'Gross Amount', selector: data=>data.gross_amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), sortable: true, right: true,
        headerStyle: {
          textAlign: 'center',
        }, },
        { name: 'Interest Rate', selector: data=>data.interest_rate.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), sortable: true, right: true,
        headerStyle: {
          textAlign: 'center',
        }, },
        { name: 'Bucor Share', selector: data=>data.bucor_share.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), sortable: true, right: true,
        headerStyle: {
          textAlign: 'center',
        }, },
        { name: 'Net Amount', selector: data=>data.net_amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }), sortable: true, right: true,
          headerStyle: {
            textAlign: 'center',
          }, },
        { name: 'Transaction Date', selector: data=>data.reg_date, sortable: true },
        { name: 'Sender', selector: data=>data.sender, sortable: true }
      ]

      setColumns(_col);

    });
    

  }

  return (
    <div>
      <h2>Bucor Padala Report</h2>
      
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          style={{zIndex:0}}
      >
        
      </DataTable>
    </div>
  );
};

export default BeuPadalaReports;
